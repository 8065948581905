.Handle {
    flex: none;
    width: 7.5px;
    height: 100%;
}

.Handle::before {
    content: '';
    border-left: 4px dotted #ccc;
    display: block;
    height: 20px;
    margin: 15px 3px;
}

.Handle:hover::before {
    border-color: #888;
}

.sticky-row.BaseTable__row {
    background-color: #009870;
}
