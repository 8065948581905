.clickableCell {
    cursor: pointer;
}

.clickableCell:hover {
    background-color: rgb(143,188,143, 0.6); 
}

.tableCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}